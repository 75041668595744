@import 'src/styles/fonts';
@import 'src/styles/main';

.upcoming-schedule-container {
    width: 90%;
    margin: 0px 5% 0px 5%;
    @include desktop {
        width: 80%;
        margin: 0px 10% 40px 10%;
    }
    @include tablet {
        width: 80%;
        margin: 0px 10% 40px 10%;
    }

    .upcoming-schedule {
        color: $fds-color-primary;
        text-align: center;
        // padding-top: 40px;

        .upcoming-schedule-title {
            border-bottom: 1px solid #979797;
            padding-bottom: 16px;

            .title-large {
                line-height: 28px;
                padding-bottom: 16px;
                color: #4d4d4d;
                font-size: 24px;
                line-height: 24px;
                text-align: center;

                @include tablet {
                    grid-template-columns: 1fr;
                    font-size: 34px;
                    line-height: 44px;
                }

                @include desktop {
                    grid-template-columns: 1fr;
                    font-size: 34px;
                    line-height: 44px;
                }
            }

            .title-small {
                color: #4d4d4d;
                font-size: 16px;
                line-height: 26px;
                text-align: center;
            }
        }
    }

    .tile-row {
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr;
        min-height: 500px;

        @include tablet {
            min-height: 260px;
            grid-template-columns: 1fr;
        }

        @include desktop {
            grid-template-columns: 1fr;
            min-height: 360px;
        }

        .tile-grid {
            display: grid;
            justify-content: center;
            grid-template-columns: 1fr;
            border-bottom: 1px solid #979797;
            grid-gap: 10px;

            @include tablet {
                grid-template-columns: 1fr 2fr 1fr;
                grid-gap: 30px;
                align-items: center;
            }

            @include desktop {
                grid-template-columns: 1fr 2fr 1fr;
                grid-gap: 30px;
                align-items: center;
            }

            .tile-item {
                text-align: initial;
                padding: 10px;
                @include desktop {
                    padding: 20px;
                }
                &.add-to-calendar-wrapper {
                    text-align: center;
                    padding: 10px 0px;
                    grid-column-start: auto;
                    grid-column-end: auto;
                    @include desktop {
                        text-align: right;
                        grid-column-end: auto;
                        grid-column-start: auto;
                        padding: 20px 0;
                    }
                    @include tablet {
                        text-align: right;
                        grid-column-end: auto;
                        grid-column-start: auto;
                    }

                    .primary-button {
                        border-radius: 1px;
                        width: 130px;
                        height: 47px;
                        span {
                            margin: 0 auto;
                        }
                    }
                    .viewSchedule {
                        margin-bottom: 30px;
                    }
                    .calendarStyle {
                        text-align: initial;
                        margin-left: 30px;
                        margin-bottom: 60px;
                        .notification-icon {
                            margin-left: 20px;
                        }
                    }
                }

                .schedule-item-image {
                    width: 100%;
                    @include desktop {
                        height: 166px;
                        width: 294px;
                    }
                }

                .schedule-date-and-time {
                    color: #00095b;
                    font-size: 16px;
                    line-height: 26px;
                    font-family: $f1-font-regular;
                }

                .schedule-topic {
                    color: #4d4d4d;
                    font-size: 16px;
                    line-height: 26px;
                    font-family: $f1-font-regular;
                    top: 20px;
                }
                .schedule-title {
                    color: #4d4d4d;
                    font-family: $f1-font-light;
                    font-size: 20px;
                    line-height: 30px;
                    @include desktop {
                        font-size: 22px;
                        padding-top: 7px;
                    }
                }
            }
        }
    }

    .calendarClass {
        padding: 10px;
        cursor: pointer;
    }
}

.upcoming-schedule-container-header {
    width: 90%;
    margin: 0px 5% 0px 5%;

    @include desktop {
        width: 80%;
        margin: 20px 10% 0px 10%;
    }
    @include tablet {
        width: 80%;
        margin: 20px 10% 0px 10%;
    }
    .more-discover-your-ford {
        color: #4d4d4d;
        font-family: $f1-font-regular;
        font-size: 16px;
        line-height: 26px;
        display: flex;
        justify-content: center;

        @include mobile {
            margin-top: 50px;
        }
    }
    .live-stream-headline {
        margin-top: 12px;
        color: #4d4d4d;
        font-family: $f1-font-light;
        font-size: 34px;
        line-height: 44px;
        text-align: center;
    }
    .live-stream-session {
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .live-stream-text {
        margin-top: 11px;
        width: 607px;
        color: #4d4d4d;
        font-family: $f1-font-regular;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        @include desktop {
            margin-top: 21px;
        }
    }
}
